// Simple js templating.
function tmpl (tmpl, obj) {
    return tmpl.replace(/%(\w*)%/g,
        function (m,key) {
            return obj.hasOwnProperty(key) ? obj[key] : '';
        }
    );
};

// Bind wave buttons to DOM nodes.
function bindWaveButtons () {
    if (!(bowser.msie && bowser.version <= 9)) {
        Waves.attach('.waveBtn', ['waves-button', 'waves-light']);
        Waves.init();
    }
};

function escapeRegExp(string) {
    return string.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
};

function replaceAll(string, find, replace) {
    return string.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}

Object.size = function(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

// A (possibly faster) way to get the current timestamp as an integer.
var now = Date.now || function() {
    return new Date().getTime();
};

// Debounce function
function debounce (func, wait, immediate) {
    var timeout, args, context, timestamp, result;

    var later = function() {
        var last = now() - timestamp;
        if (last < wait) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                context = args = null;
            }
        }
    };

    return function() {
        context = this;
        args = arguments;
        timestamp = now();
        var callNow = immediate && !timeout;
        if (!timeout) {
            timeout = setTimeout(later, wait);
        }
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };
}

/**
 * Extend the Array object
 * @param candid The string to search for
 * @returns Returns the index of the first match or -1 if not found
 */
Array.prototype.searchFor = function(candid) {
    for (var i=0; i<this.length; i++)
        if (this[i].toLowerCase().indexOf(candid.toLowerCase()) == 0)
            return i;
    return -1;
};

function toTitleCase (str) {
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}