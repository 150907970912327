$('a').addClass('noclick');

(function () {
    var $body = $(document.body);

    $(document).on('click', '.declaration .submit.disabled', function (e) {
        e.preventDefault();
    });

    $(document).on('ready', function () {
        // set timeout
        if ($(document.body).hasClass('save-on-exit') || $(document.body).hasClass('timed')) {
            var tid = setTimeout(sessionExpiring, 900000);
        }
        function dump(obj) {
            var out = '';
            for (var i in obj) {
                out += i + ": " + obj[i] + "\n";
            }

            alert(out);

            // or, if you wanted to avoid alerts...
            var pre = document.createElement('pre');
            pre.innerHTML = out;
            document.body.appendChild(pre)
        }
        function sessionExpiring() {
            var modal = new Modal();

            modal.openModalDialog({
                heading: 'Your session is about to expire.',
                body: 'Do you wish to continue editing your will form?<br><br>',
                buttons: 'continue dont-continue'
            });
            var killtid = setTimeout(sessionEnding, 240000);
            $(modal.modalHTML).on('click', '[data-btn-continue]', function () {
                clearTimeout(killtid);
                tid = setTimeout(sessionExpiring, 900000); // repeat myself
                var jqxhr = $.get( "/GetNewAuthToken", function(data) {
                    if(data['Result'] != 0) {
                        if ($(self).attr('target') === '_blank') {
                            var win = window.open('/', '_blank');
                            win.focus();
                        } else {
                            window.location = '/';
                        }
                    }
                })
                .done(function() {
                    new Modal().closeModalDialog();
                })
                .fail(function() {
                    if ($(self).attr('target') === '_blank') {
                        var win = window.open('/', '_blank');
                        win.focus();
                    } else {
                        window.location = '/';
                    }
                })
                .always(function() {
                });
            });

            $(modal.modalHTML).on('click', '[data-btn-dont-continue]', function () {
                clearTimeout(killtid);
                tid = setTimeout(sessionExpiring, 900000); // repeat myself

                if ($(self).attr('target') === '_blank') {
                    var win = window.open('/', '_blank');
                    win.focus();
                } else {
                    window.location = '/';
                }
            });

            function sessionEnding() {
                clearTimeout(killtid);
                clearTimeout(tid);

                if($('#wills-form-js').length) {
                    $('#_goto').val('/');
                    $('#wills-form-js').trigger('submit');
                } else if ($('#assets-form-js').length) {
                    $('#_goto').val('/');
                    $('#assets-form-js').trigger('submit');
                } else if ($('#details-form-js').length) {
                    $('#_goto').val('/');
                    $('#details-form-js').trigger('submit');
                } else if ($('#epa-pcw-form-js').length) {
                    $('#_goto').val('/');
                    $('#epa-pcw-form-js').trigger('submit');
                } else if ($('#epa-property-form-js').length) {
                    $('#_goto').val('/');
                    $('#epa-property-form-js').trigger('submit');
                } else {
                    window.location = "/";
                }
            }

        }
    });

    function initAjaxPageLoad () {
        // Hide the page loader bar.
        $('#page-loader-js').removeClass('play').hide(0);

        $.material.checkbox();
        $.material.radio();
        bindWaveButtons();

        window.lastCompletion = null;

        // Bind form validation and input masks.
        new FormValidation();
        new Masks();
        new CheckboxAccordion();

        // Destroy mobile swipe nav if exists.
        if (sliderDom.data('Swipe') && typeof sliderDom.kill === 'function') {
            sliderDom.data('Swipe').kill();
        }
    }

    var storeSliderOriginal = $('#main-nav-slider-js').html(),
        startSlide = $('#main-nav-slider-js').find('a').index($('#main-nav-slider-js').find('a.active')),
        sliderDom = $('#main-nav-slider-js'),
        set = '';

    var poll = debounce(function () {
        new Complete();
        poll();

        if (sliderDom.length > 0 && $(window).width() < 768) {
            if (set === 'desktop' || set === '') {
                if (!sliderDom.data('Swipe')) {
                    sliderDom.Swipe({
                        continuous: false,
                        slideCount: 8,
                        startSlide: startSlide
                    });
                }
            }

            set = 'mobile';
        } else {
            if (set === 'mobile') {
                sliderDom.html(storeSliderOriginal);

                if (sliderDom.data('Swipe') && typeof sliderDom.kill === 'function') {
                    sliderDom.kill();
                }
                sliderDom.data('Swipe', null);
            } else {
                if (sliderDom.find('[data-index]').length > 0) {
                    sliderDom.html(storeSliderOriginal);
                }
            }

            set = 'desktop';
        }
    }, 400);

    poll();

    $('#swipe-left-js').on('click', function () {
        if (sliderDom.data('Swipe')) {
            sliderDom.data('Swipe').prev();
        }
    });

    $('#swipe-right-js').on('click', function () {
        if (sliderDom.data('Swipe')) {
            sliderDom.data('Swipe').next();
        }
    });

    new CategorySelector();

    initAjaxPageLoad();

    // Show/hide toggle block w/ button
    $(document).on('show hide', '[data-show-hide-block]', function (e) {
        var element = $('#' + $(this).attr('data-enable'));

        if (e.type === 'show') {
            element.hide(0);
        } else {
            element.show(0);
        }
    });

    if (bowser.ipad) {
        $('#_viewport').attr('content', 'maximum-scale=1');
    }

    // Open close mobile browser navigation pane.
    $(document).on('click', '#main-nav-js button', function (e) {
        e.preventDefault();

        var self = $(this);

        if (self.hasClass('open')) {
            self.addClass('closing');
            self.removeClass('open');
            setTimeout(function () {
                self.removeClass('closing');
            }, 300);
        } else {
            self.addClass('open opening');
            setTimeout(function () {
                self.removeClass('opening');
            }, 300);
        }
    });

    $('#main-nav-slider-js').on('click', 'a', function () {
        $(document.body).addClass('pending');
    });

    if ($(document.body).hasClass('executor-trustees')) {
        $(document.body).addClass('editing');
    }

    // Window close message.
    $(window).on('beforeunload', function (e) {
        if (!$(document.body).hasClass('saved') && $(document.body).hasClass('save-on-exit') && !$(document.body).hasClass('pending') && $(document.body).hasClass('editing') && !$(document.body).hasClass('sent')) {
            e.preventDefault();

            var message = 'If you want to save your changes, stay on this page and click Save.',
                e = e || window.event;
            // For IE and Firefox
            if (e) {
                e.returnValue = message;
            }

            // For Safari
            return message;
        }
    });

    if ($('#main-nav-slider-js').length > 0) {
        $('#page-state-js').on('click', 'a', function (e) {
            var self = this;

            if (!$(document.body).hasClass('saved') && !$.contains($('#main-nav-slider-js')[0], this) && $(document.body).hasClass('editing') && !$(document.body).hasClass('sent') && !$(this).hasClass('select-target') && !$(this).hasClass('highlightFields') && $(this).attr('href') !== '#') {
                e.preventDefault();

                var modal = new Modal();
                modal.openModalDialog({
                    heading: 'You are about to leave editing your information.',
                    body: 'Do you wish to save your changes?<br><br>',
                    buttons: 'cancel save dont-save'
                });

                $(modal.modalHTML).on('click', '[data-btn-save]', function () {
                    $('#_goto').val(self.href);
                    $('[id$=-form-js]').trigger('submit');
                });

                $(modal.modalHTML).on('click', '[data-btn-dont-save]', function () {
                    $(document.body).removeClass('editing');

                    if ($(self).attr('target') === '_blank') {
                        var win = window.open(self.href, '_blank');
                        win.focus();
                    } else {
                        window.location = self.href;
                    }
                });
            } else if ($.contains($('#main-nav-slider-js')[0], this) && $(document.body).hasClass('editing') && !$(document.body).hasClass('sent')  && !$(document.body).hasClass('timed') && !$(this).hasClass('select-target') && !$(this).hasClass('highlightFields') && $(this).attr('href') !== '#') {
                e.preventDefault();
                $('#_goto').val(self.href);
                $('[id$=-form-js]').trigger('submit');
            }
        });
    }

    $(document).on('change', '[data-options]', function () {
        var self = $(this),
            options = (self.attr('data-options')) ? self.attr('data-options').split('|') : null,
            active = self.attr('data-options-active');

        for (var i = options.length - 1; i >= 0; i--) {
            $('#' + options[i]).hide(0);
        }

        $('#' + active).show(0);
    });

    $(document).on('change', 'input, select', function () {
        if ($('[data-combo]').length > 0) {
            runComboCheck();
        }
    });

    $(document).on('click', 'body.accept-terms button[name="cancel"]', function () {
        window.location = "/";
    });

    $("body.accept-terms").validate({
        errorPlacement: function(error, element) {
            error.appendTo( $('#error') );
        }
    });

    $('input[data-currency]').attr({ maxLength : 12 });

    function runComboCheck () {
        var comboDOM = $('[data-combo]'),
            comboElements = (comboDOM.attr('data-combo')) ? comboDOM.attr('data-combo').split('|') : null,
            comboMatches = (comboDOM.attr('data-combo-match')) ? comboDOM.attr('data-combo-match').split('|') : null,
            comboResults = (comboDOM.attr('data-combo-result')) ? comboDOM.attr('data-combo-result').split('|') : null,
            comboPairs = (comboDOM.attr('data-combo-pair')) ? comboDOM.attr('data-combo-pair').split(',') : null,
            comboPairsArray = [],
            comboMatchesArray = [],
            results = [];

        for (var i = 0; i < comboPairs.length; i++) {
            comboPairsArray[i] = comboPairs[i].split('=');
        }

        for (var i = 0; i < comboMatches.length; i++) {
            comboMatchesArray[i] = comboMatches[i].split(',');
        }

        for (var i = 0; i < comboElements.length; i++) {

            var elem = $('[name="' + comboElements[i] + '"]'),
                elemValue = elem.val();

            if (elem.attr('type') === 'radio') {
                elemValue = '';
                if (elem.filter(':checked')) {
                    elemValue = elem.filter(':checked').val();
                }
            }

            if (!elemValue) {
                elemValue = '';
            } else {
                for (var j = 0; j < comboPairsArray.length; j++) {
                    if (comboPairsArray[j][0] === elemValue.toLowerCase()) {
                        elemValue = comboPairsArray[j][1];
                    }
                }
            }

            results.push(elemValue.toLowerCase());
        }

        for (var i = 0; i < comboResults.length; i++) {
            $('.' + comboResults[i]).hide(0);
        }

        for (var i = 0; i < comboMatchesArray.length; i++) {
            if (arraysEqual(comboMatchesArray[i], results)) {
                $('.' + comboResults[i]).show(0);
            }
        }
    }

    function adjustSigninForm () {
        var formDom = $('body.sign-in form'),
            formHeight = formDom.outerHeight();

        formDom.css('margin-top', '-' + formHeight / 2 + 'px');
    }

    if ($('body.sign-in form').length > 0) {
        adjustSigninForm();
    }

    $(document).on('change', '[data-options-many]', function () {
        var self = $(this),
            options = (self.attr('data-options-many')) ? self.attr('data-options-many').split('|') : null,
            active = self.attr('data-options-many-active');

        for (var i = options.length - 1; i >= 0; i--) {
            if (options[i] !== active) {
                $('[id="' + options[i] + '"]').hide(0);
            }
        }

        if (this.checked) {
            $('[id="' + active + '"]').show(0);
        }
    });

    $(document).on('click', 'a[href^="tel"]', function (e) {
        if ($(window).width() >= 768) {
            e.preventDefault();
        }
    });

    $(document).on('click', '#main-nav-js .list > div', function () {
        $(this).find('> ul').toggleClass('open');
    });

    $('input:not([type="radio"]), select').trigger('change');
    $('input[type="radio"]:checked').trigger('change');

    $('select').on('change', function (e) {
        $(this).removeClass('highlighted');
    });

    $('select, input, textarea, .mask-date, .mask-phone-number, .mask-currency').on('keyup change input', function () {
        $(document.body).addClass('editing');
    });

    $(document).on('change customval', 'select', function (e) {
        var self = this,
            selfEl = $(self),
            customValue = selfEl.siblings('input[name^="actual-"]').val();

        if (selfEl.siblings('input[name^="actual-"]').length == 0) { return; }

        if (self.value === 'Other') {
            selfEl.addClass('other-selected');
            selfEl.siblings('a.select-target').addClass('other-selected');
            selfEl.siblings('input.other-selected-input').remove();
            $('<input type="text" class="other-selected-input">').insertBefore(selfEl);

            if (e.type === 'change') {
                selfEl.siblings('input[name^="actual-"]').val('');
                setTimeout(function () {
                    selfEl.siblings('input.other-selected-input').trigger('focus');
                }, 0);
            }

        } else {
            selfEl.removeClass('other-selected');
            selfEl.siblings('input.other-selected-input').remove();
            selfEl.siblings('a.select-target').removeClass('other-selected');
            selfEl.siblings('input[name^="actual-"]').val(self.value);
        }

        if (customValue && e.type !== 'change') {
            selfEl.siblings('input.other-selected-input').val(customValue);
        }
    });

    function customVal (el) {
        if (typeof el.selectInstance === 'object' || typeof el.selectInstance === 'function') {
            $(el).trigger('customval');
            return;
        }

        setTimeout(function () {
            customVal(el);
        }, 100);
    }

    $('select').each(function () {
        customVal(this);
    });


    // new added 2016-02-10
    $(document).on('keyup', 'input.other-selected-input', function () {
        var self = this,
            selfEl = $(self);
        selfEl.siblings('input[name^="actual-"]').val(self.value);
    });
    $('input[name="exisiting-epa"]').on('click', function () {
        if ($('[name="exisiting-epa"]:checked').val() === 'No') {
            $('.fileUpload').find('span').trigger('click');
        }
    });

    $('#existing-pa-upload').on('change', function () {
        var filename = (this.files[0]) ? this.files[0].name : false;
        $('#existing-pa-upload-display').val(filename);

        if (filename) {
            $(this).parent().addClass('added');
        } else {
            $(this).parent().removeClass('added');
        }
    });

    $('.fileUpload').find('span').on('click', function () {
        var hasFile = $(this).parent().hasClass('added');

        if (hasFile) {
            $('#existing-pa-upload-display').val('No file selected');
            $('#existing-pa-upload').val('');
            $(this).parent().removeClass('added');
        }
    });

    $('.removefile').on('click', function () {
        $('#existing-pa-upload-display').val('No file selected');
        $('#existing-pa-upload').val('');
        $('.existing-pa-panel').hide();
        $('.new-pa-panel').show();
        return false;
    });
})();

window.onpageshow = function(event) {
    if (event.persisted) {
        window.location.reload()
    }
};