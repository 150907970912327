// Duplicate or clone a block of html.
$(document).on('click', '[data-dupe]', function (e) {
    e.preventDefault();

    var dupeCount = 1;

    var rows = $(this).parent().parent().parent().find('.duplicate, .duplicated').length;

    if (rows > 1) {
        dupeCount = rows;
    }

    var clone = $('#' + $(this).attr('data-dupe')).clone();

    clone.wrapAll('<div>').parent().find('.duplicate').removeAttr('id').removeClass('duplicate').addClass('duplicated');
    clone.wrapAll('<div>').parent().find('input, select').removeClass('highlighted').removeClass('error').removeAttr('aria-invalid').removeAttr('aria-valid').removeAttr('aria-required');
    clone.wrapAll('<div>').parent().find('label.error').remove();

    clone.wrapAll('<div>').parent().find('input, textarea, select, label, div').each(function () {
        if ($(this).attr('name') && $(this).attr('name').indexOf('[0]') > 0) {
            $(this).attr('name', $(this).attr('name').replace('[0]', '[' + dupeCount + ']'));
        }

        if ($(this).attr('for') && $(this).attr('for').indexOf('[0]') > 0) {
            $(this).attr('for', $(this).attr('for').replace('[0]', '[' + dupeCount + ']'));
        }

        if ($(this).attr('id') && $(this).attr('id').indexOf('[0]') > 0) {
            $(this).attr('id', $(this).attr('id').replace('[0]', '[' + dupeCount + ']'));
        }

        if ($(this).attr('class') && $(this).attr('class').indexOf('[0]') > 0) {
            $(this).attr('class', $(this).attr('class').replace('[0]', '[' + dupeCount + ']'));
        }

        if ($(this).attr('data-dynamic-options')) {
            $(this).find('option:not(:first-child)').remove();
        }
    });

    clone.wrapAll('<div>').parent().find('[data-select-toggle-ids]').each(function () {
        $(this).attr('data-select-toggle-ids', replaceAll($(this).attr('data-select-toggle-ids'), '[0]', '[' + dupeCount + ']'));
    });

    clone.wrapAll('<div>').parent().find('[data-select-toggle-classes]').each(function () {
        $(this).attr('data-select-toggle-classes', replaceAll($(this).attr('data-select-toggle-classes'), '[0]', '[' + dupeCount + ']'));
    });

    clone.wrapAll('<div>').parent().find('[data-options-many-active]').each(function () {
        $(this).attr('data-options-many-active', replaceAll($(this).attr('data-options-many-active'), '[0]', '[' + dupeCount + ']'));
    });

    clone.wrapAll('<div>').parent().find('[data-options-many]').each(function () {
        $(this).attr('data-options-many', replaceAll($(this).attr('data-options-many'), '[0]', '[' + dupeCount + ']'));
    });

    clone.wrapAll('<div>').parent().find('[data-ajax-update-elm]').each(function () {
        $(this).attr('data-ajax-update-elm', replaceAll($(this).attr('data-ajax-update-elm'), '[0]', '[' + dupeCount + ']'));
    });

    clone.wrapAll('<div>').parent().find('div').each(function () {
        $(this).attr('class') && $(this).attr('class', replaceAll($(this).attr('class'), '[0]', '[' + dupeCount + ']'));
    });

    clone.find('.select-target').remove();
    clone.find('input').not('[type="checkbox"], [type="radio"]').val('');
    clone.find('textarea').val('');
    clone.find('input').removeAttr('checked');
    clone.find('select').val('');

    var elem = clone.appendTo('#' + $(this).attr('data-dupe-insert-to'));

    // if (clone.find('select').length > 0) {
    //     Select.init();
    // }

    if (clone.find('input[type="radio"]').length > 0) {
        $.material.radio();
    }

    if (clone.find('input[type="checkbox"]').length > 0) {
        $.material.checkbox();
    }

    elem.find('input[name="other-selected-input"]').remove();

    clone.find('[data-select-toggle-classes]').each(function () {
        var tc = $(this).attr('data-select-toggle-classes');
        var ns = tc.replace(/-label0/g, "-label"+dupeCount);
        $(this).attr('data-select-toggle-classes',ns);

    });

    clone.find('div[class*="-label0"]').each(function () {
        var tc = $(this).attr('class');
        var ns = tc.replace(/-label0/g, "-label"+dupeCount);
        $(this).attr('class',ns);
    });

    elem.find('[data-select-options]').trigger('change');
    elem.find('[data-options-many]').trigger('change');

    clone.find('select').val('');

    clone.find('[data-options-default]').each (function () {
        $(this).val($(this).attr('data-options-default'));
    });

    new Masks();

    setTimeout(function () {
        tabIndexFix();
        // Select.init();
        $('select').removeAttr('tabindex');
    }, 500);

    $('.mask-date').each(function () {
        $('[name="' + this.name + '"]').rules('add', {
            required: false
        });
    });

    $(document.body).addClass('editing');

    if (!bowser.android) {
        $('body.assets-form').find('[data-currency]').maskMoney({
            precision: 0,
            prefix: '$',
            allowZero: true
        });
    } else {
        $('body.assets-form').find('[data-currency]').on('keydown', function (e) {
            if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode >= 65 && e.keyCode <= 90)) {
                e.preventDefault();
                var chr_code = e.keyCode - 48 * Math.floor(e.keyCode / 48),
                    chr = String.fromCharCode((96 <= e.keyCode) ? chr_code: e.keyCode);

                this.value = '$' + numberWithCommas(this.value.replace(/[^0-9.]/g, '') + chr.replace(/[^0-9.]/g, ''));
            }
        }).on('blur', function () {
            this.value = '$' + numberWithCommas(this.value.replace(/[^0-9.]/g, ''));
        });
    }

    clone.find('select').each(function () {
        $(this).trigger('customval');
    });

    dupeCount++;
});

$(document).on('click', '.duplicated .remove-x', function (e) {
    e.preventDefault();
    $(this).closest('.duplicated').remove();
    $(document.body).addClass('editing');
});

function tabIndexFix () {
    var tabindex = 1;

    var firstBtn = $('.completion-panel:first .btn');

    $('input, select, .btn, textarea, .select-target').not('[type="hidden"]').not(firstBtn).each(function () {
        $(this).attr('tabindex', tabindex);
        tabindex++;
    });
}

tabIndexFix();