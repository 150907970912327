(function(root, factory) {
    if(typeof exports === 'object') {
        module.exports = factory();
    }
    else if(typeof define === 'function' && define.amd) {
        define('CheckboxAccordion', [], factory);
    }

    root.CheckboxAccordion = factory();

}(this, function() {

    var CheckboxAccordion = function () {
        var self = this;

        $(document).on('click', '.checkbox-accordion .title input', function () {
            var selected = $(this).parent().parent().parent().parent(),
                accordion = selected.parent(),
                others = accordion.find('.panel').not(selected);
            others.removeClass('open');
            others.find('input').not('[name="executor"], [type="checkbox"], [type="radio"]').val('');
            others.find('select').not('[name="executor"], [type="checkbox"], [type="radio"]').val('').trigger('change');
            others.find('input').not('[name="executor"]').removeAttr('checked');

            if(selected.hasClass('open')) {
                selected.removeClass('open');
            } else {
                selected.addClass('open');
            }
        });

        $(document).on('click', '.checkbox-accordion [data-toggle]', function () {
            var selected = $(this).parent(),
                accordion = selected.parent(),
                others = accordion.find('.panel').not(selected);
            others.removeClass('open');

            if(selected.hasClass('open')) {
                selected.removeClass('open');
            } else {
                selected.addClass('open');
            }

            window.location.hash = selected.index() + 1;
        });

        $('.checkbox-accordion .title input:checked').trigger('click');

        if (window.location.hash) {
            var hash = window.location.hash.replace('#', ''),
                panel = $($('.checkbox-accordion').find('.panel').get(hash-1));

            panel.find('[data-toggle]').trigger('click');

            $(window).scrollTop(panel.offset().top);
        }
    };

    return CheckboxAccordion;
}));