(function(root, factory) {
    if(typeof exports === 'object') {
        module.exports = factory();
    }
    else if(typeof define === 'function' && define.amd) {
        define('Sticky', [], factory);
    }

    root.Sticky = factory();

}(this, function() {

    var StickyClass = function () {
        var self = this;

        self.domCache = {
            'documentbody': $(document.body),
            'stickyElement': $('.stickyElement')
        };
    };

    StickyClass.prototype.stick = debounce(function (Sticky) {
        var self = Sticky;

        $.each(self.domCache.stickyElement, function () {
            var elem = $(this);

            if (self.domCache.documentbody.scrollTop() >= elem.parent().offset().top - 24) {
                if (!elem.hasClass('stuck')) {
                    elem.addClass('stuck');
                }
            } else {
                elem.removeClass('stuck');
            }
        });
    }, 10);

    var Sticky = new StickyClass();

    $(window).on('scroll', function () {
        Sticky.stick(Sticky);
    });

    Sticky.stick(Sticky);

    return Sticky;
}));