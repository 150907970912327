(function(root, factory) {
    if(typeof exports === 'object') {
        module.exports = factory();
    }
    else if(typeof define === 'function' && define.amd) {
        define('FormValidation', [], factory);
    }

    root.FormValidation = factory();

}(this, function() {
    // Checkbox group validation rule.
    $.validator.addMethod('require-one', function (value) {
        return $('.require-one:checked').size() > 0;
    }, 'At least one is required.');

    $.validator.addMethod('valid-dob', function (value) {
        var data = value.split('/');

        if (!value || value === '') {
            return true;
        }

        if (data.length > 2 && data[0].length === 2 && data[1].length === 2 && data[2].length === 4) {
            var isvalid = moment(value, 'DD/MM/YYYY').isValid();

            if (isvalid) {
                return true;
            }
        }
        return false;
    }, 'Date of birth must be valid and in the format dd/mm/yyyy. e.g. 20/05/1982');

    var FormValidation = function () {

        var self = this;

        self.formElement = $('form');

        // Exit if we have no form or more than one form.
        if (self.formElement.length !== 1) {
            return;
        }

        self.requiredGroup = $('[data-required-group]');

        // If validator is already bound to form, remove it.
        if (self.formElement.data('validator')) {
            self.formElement.data('validator').resetForm();
        }
        self.formElement.removeData('validator');

        self.requiredGroupNames = '';

        // If we have a require group and we require one to be ticked.
        if (self.requiredGroup.length > 0 && self.requiredGroup.find('.require-one').length > 0) {
            self.requiredGroupNames = $.map(self.requiredGroup.find('.require-one'), function (e, i) {
                return $(e).attr('name')
            }).join(' ');
        }

        if ($('[id$=-form-js]').length > 0) {
            $('input, select, textarea').addClass('ignore');
            $('#declaration-agree-js').removeClass('ignore');
            $('input[name="required-completion"]').removeClass('ignore');

            var emailInputsDom = $('input[type="email"]'),
                selectInputsDom = $('select');

            emailInputsDom.on('keypress', function() {
                if ($(this).val() !== '') {
                    $(this).removeClass('ignore');
                } else {
                    $(this).addClass('ignore');
                }
            });

            emailInputsDom.on('blur', function () {
                if ($(this).val() !== '') {
                    $(this).removeClass('ignore');
                } else {
                    $(this).addClass('ignore');
                    $(this).removeClass('error');
                    $(this).parent().find('.error').remove();
                }
            });

            selectInputsDom.each(function () {
                if ($(this).val() === '') {
                    $(this).addClass('invalid');
                } else {
                    $(this).removeClass('invalid');
                }
            });

            selectInputsDom.on('change', function () {
                if ($(this).val() === '') {
                    $(this).addClass('invalid');
                } else {
                    $(this).removeClass('invalid');
                }
            });

            $('.mask-date').removeClass('ignore');

            $.validator.addClassRules('mask-date', {
                'valid-dob': true,
                'required': false
            });

            // Validate library initializer.
            self.validator = self.formElement.validate({
                ignore: '.ignore,:hidden',
                groups: {
                    checks: self.requiredGroupNames
                },
                errorPlacement: function(error, element) {
                    error.appendTo(element.parent());
                },
                submitHandler: function (form) {
                    $(document.body).addClass('pending');
                    $(form).find('[type="submit"]').attr('disabled', 'true');
                    return true;
                }
            });

            $('.mask-date').each(function () {
                $('[name="' + this.name + '"]').rules('add', {
                    required: false
                });
            });
        } else {
            // Validate library initializer.
            self.validator = self.formElement.validate({
                ignore: '.ignore,:hidden',
                errorPlacement: function(error, element) {
                    if($('body.accept-terms').length > 0) {
                        error.appendTo(document.getElementById('error'));
                    } else {
                        error.appendTo(element.parent());
                    }
                },
                submitHandler: function (form) {
                    $(document.body).addClass('pending');
                    $(form).find('[type="submit"]').attr('disabled', 'true');
                    return true;
                }
            });
        }

        window.validatorForm = self.validator;

        $('select').on('change', (function (val) {
            $(this).valid();
        }));

        self.toggle();
    };

    // Toggle show/hide form fields
    FormValidation.prototype.toggle = function () {
        var self = this;

        // Show hide form rules.
        $('[data-on]').each(function () {
            var selfToggle = $(this),
                triggerEvent = selfToggle.attr('data-on'),
                show = $('#' + selfToggle.attr('data-show')),
                hide = $('#' + selfToggle.attr('data-hide'));

            // If we require a value.
            if (triggerEvent === 'entry') {
                selfToggle.on('keyup change', FormValidation.showItem).on('blur', FormValidation.hideItem);
                selfToggle.trigger('change');
            }

            if (triggerEvent === 'click') {
                selfToggle.on('click', FormValidation.showItem);
            }
        });
    };

    // Show form field.
    FormValidation.showItem = function (e) {
        if (e.type === 'click') {
            e.preventDefault();
        }

        var selfToggle = $(e.currentTarget),
            show = $('#' + selfToggle.attr('data-show')),
            hide = $('#' + selfToggle.attr('data-hide')),
            validator = ($('form').data('validator'));

        if (e.type === 'click') {
            if (!show.is(':visible')) {
                show.show(0);
                show.trigger('show');
                //show.find('input').removeClass('ignore');
                selfToggle.addClass('open');
            } else {
                hide.hide(0);
                hide.trigger('hide');
                show.find('input').addClass('ignore');
                show.find('input').val('');
                show.find('select').val('');
                selfToggle.removeClass('open');
            }
        } else {
            if (selfToggle.attr('type') === 'radio') {
                if (selfToggle.is(':checked')) {
                    show.show(0);
                    hide.hide(0);
                }
            } else {
                if (selfToggle.val() !== '') {
                    show.show(0);
                    //show.find('input').removeClass('ignore');
                } else {
                    hide.hide(0);
                    show.find('input').addClass('ignore');
                }
            }
        }
    };

    // Hide form field and re-validate.
    FormValidation.hideItem = function (e) {
        var selfToggle = $(e.currentTarget),
            show = $('#' + selfToggle.attr('data-show')),
            hide = $('#' + selfToggle.attr('data-hide')),
            validator = ($('form').data('validator'));

        //if (validator) {
            //validator.element(show.find('input'));
        //}
    };

    return FormValidation;
}));

// Highlight required fields.
$(document).on('click', '.highlightFields', function (e) {
    e.preventDefault();

    var selected = false;

    $('input[required], select[required]').each(function () {
        var self = $(this);

        if (self.val() === '' || !self.attr('checked')) {
            if (!selected && self.is(':invalid')) {
                self.focus();
                selected = true;
            }
            self.addClass('highlighted');

            //if (self.is(':invalid')) {
                //self.addClass('animated shake');
            //}

            //setTimeout(function () {
            //    self.removeClass('animated shake');
            //}, 305);
        }
    });
});

// Skip form block
$(document).on('change', '.skip-section input', function () {
    if(this.checked) {
        $(this).closest('section').find('input[type="text"]').val('');
        $(this).closest('section').find('textarea').val('');
        $(this).closest('section').find('input[type="radio"]').attr('checked', false);
        $(this).closest('section').find('select').each(function () {
            $(this)[0].selectedIndex = '0';
            $(this).trigger('change');
        });

        $(this).closest('section').find('.duplicated').remove();

        $(this).closest('section').find('.skip-block').hide(0);
    } else {
        $(this).closest('section').find('.skip-block').show(0);
    }
});