(function(root, factory) {
    if(typeof exports === 'object') {
        module.exports = factory();
    }
    else if(typeof define === 'function' && define.amd) {
        define('Masks', [], factory);
    }

    root.Masks = factory();

}(this, function() {

    // Add custom formats.
    $.fn.formatter.addInptType('C', /^[0-9.,]+$/); // Allow currency formats.
    $.fn.formatter.addInptType('P', /^[0-9()+ ]+$/); // Allow phone number formats.

    var Masks = function () {
        var self = this;

        $('.mask-credit-card').formatter({
            'pattern': '{{9999}}-{{9999}}-{{9999}}-{{9999}}'
        });

        $('.mask-currency').formatter({
            'pattern': '${{CCCCCCCCCCCCC}}'
        });

        $('.mask-date').formatter({
            'pattern': '{{99}}/{{99}}/{{9999}}'
        });

        $('.mask-phone-number').formatter({
            'pattern': '{{PPPPPPPPPPPPPPPPPP}}'
        });
    };

    return Masks;
}));