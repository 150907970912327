(function (global) {
	'use strict';

	global.DocumentApi = (function (options) {

		function createCORSRequest() {
			var xhr = new XMLHttpRequest();
			if ("withCredentials" in xhr)
				return xhr;							// Check if the XMLHttpRequest object has a "withCredentials" property. "withCredentials" only exists on XMLHTTPRequest2 objects.
			else if (typeof XDomainRequest != "undefined")
				return new XDomainRequest();		// Otherwise, check if XDomainRequest. XDomainRequest only exists in IE, and is IE's way of making CORS requests.

			// Otherwise, CORS is not supported by the browser.
			return null;
		}

		var xhrBody = {
			'text': function(xhr){ return xhr.responseText; },
			'document': function(xhr){ return xhr.responseXML; },
			'default': function(xhr){ return xhr.response; }
		};

		function responseBody(xhr) {
			return (xhrBody[xhr.responseType] || xhrBody['text'])(xhr);
		}

		function asyncRequest(parameters) {
			var defaults = { method: 'GET', data: {}, response: null, progressCallback: null };
			var params = _.assign(defaults, parameters);

			var deferred = $.Deferred();

			var xhr = createCORSRequest();
			if (!xhr)
				return deferred.reject(params.response, responseBody(xhr), null, xhr);
			
			xhr.upload.addEventListener("progress", params.progress, false);
			xhr.upload.addEventListener("error", function (e) { deferred.reject(params.response, responseBody(xhr), e, xhr); }, false);
			xhr.upload.addEventListener("abort", function (e) { deferred.reject(params.response, responseBody(xhr), e, xhr); }, false);
			xhr.addEventListener("load", function(e){ deferred.resolve(params.response, responseBody(xhr), e, xhr); }, false);
			xhr.addEventListener("error", function (e) { deferred.reject(params.response, responseBody(xhr), e, xhr); }, false);
			xhr.addEventListener("abort", function (e) { deferred.reject(params.response, responseBody(xhr), e, xhr); }, false);

			xhr.onreadystatechange = function (e) {
				if (xhr.readyState == 4)
					return xhr.status == 200 || xhr.status == 201 || xhr.status == 202 || xhr.status == 204 ? deferred.resolve(params.response, responseBody(xhr), e, xhr) : deferred.reject(params.response, responseBody(xhr), e, xhr);
			};

			var form = new FormData();
			_.each(params.data, function(val, key){ _.isArray(val) ? _.each(val, function(a){ form.append(key + '[]', a); }) : form.append(key, val); });

			xhr.open(params.method, params.url, true);
			xhr.send(form);

			return deferred.promise();
		}

		var api = {
			host: options.host,

			isSupported: function () {
				var xhr = createCORSRequest();

				xhr.open('GET', this.host + '/api/healthcheck', false)
				xhr.send();
				return xhr.responseText;
			},
			fileExtensions: function (authToken) {
				return $.ajax({ dataType: 'json', url: this.host + '/api/fileextensions', method: 'GET' });
			},
			list: function (authToken) {
				return $.ajax({ dataType: 'json', url: this.host + '/api/documents', method: 'GET', data: { authToken: authToken } });
			},
			upload: function (authToken, file, tags, progressCallback) {
				return asyncRequest({ method: 'POST', url: this.host + '/api/documents', data: { authToken: authToken, files: file, tags: tags }, response: file, progress: progressCallback });
			},
			edit: function (authToken, fields) {
				return asyncRequest({ method: 'PUT', url: this.host + '/api/document/' + fields.id, data: { authToken: authToken, name: fields.name, description: fields.description }, response: fields });
			},
			url: function (authToken, id) {
				return this.host + '/api/document/' + id + '?authToken=' + authToken;
			},
			delete: function (authToken, id) {
				return asyncRequest({ method: 'DELETE', url: this.host + '/api/document/' + id, data: { authToken: authToken }, response: id });
			},
			share: function (authToken, id, shares) {
				return asyncRequest({ method: 'POST', url: this.host + '/api/document/' + id + '/share', data: { authToken: authToken, id: id, shares: shares }, response: { id: id, shares: shares } });
			},
			shareAdvisor: function (authToken, id, shared) {
				return asyncRequest({ method: 'PUT', url: this.host + '/api/document/' + id + '/adviser', data: { authToken: authToken, id: id, shared: shared }, response: { id: id, isSharedAdviser: shared } });
			}
		};

		return api;
	})(global.DocumentApi || {});
})(window);
