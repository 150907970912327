$(document).on('keyup keydown', '[data-typeahead]', function (e) {
    var self = $(this),
        dataList = JSON.parse(self.attr('data-typeahead')),
        valueArray = self.val().split(', '),
        suggestionIndex = dataList.searchFor(self.val()),
        suggestion = dataList[suggestionIndex],
        element = $('<div class="typeahead"></div>'),
        i = valueArray.length - 1;

    self.parent().find('.typeahead').remove();

    suggestionIndex = dataList.searchFor(valueArray[i]);
    suggestion = dataList[suggestionIndex];

    if (suggestion && valueArray[i] !== '' && !bowser.android && !bowser.ios) {

        if (e.which === 39 || e.which == 13) {
            e.preventDefault();
            self.val(toTitleCase(self.val() + suggestion.substring(valueArray[i].length, suggestion.length)) + ', ');
        } else {
            self.parent().css('position', 'relative');

            element.html('<span class="blanked">' + self.val() + '</span>' + suggestion.substring(valueArray[i].length, suggestion.length)).css({
                'position': 'absolute',
                'top': '1px',
                'color': '#BEBEBE',
                'font-size': '13px',
                'margin-bottom': '0.75em',
                'padding': '1em'
            });

            element.find('.blanked').css('opacity', 0);

            element.insertAfter(self);
        }
    }
});