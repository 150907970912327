(function(root, factory) {
    if(typeof exports === 'object') {
        module.exports = factory();
    }
    else if(typeof define === 'function' && define.amd) {
        define('CategorySelector', [], factory);
    }

    root.CategorySelector = factory();

}(this, function() {

    var CategorySelector = function () {
        var self = this;

        $(document).on('change', '[data-ajax-categories]', function () {
            var updateElement = $('[name="' + $(this).attr('data-ajax-update-elm') + '"]'),
                updateElementItems = updateElement.attr('data-dynamic-options'),
                updateElementSelected = updateElement.attr('data-dynamic-options-selected'),
                empty = '<option value="">-- Select --</option>',
                options = [];

            if (updateElementItems) {
                updateElementItems = JSON.parse(updateElementItems);

                if (updateElementItems[$(this).val()]) {
                    updateElementItems = updateElementItems[$(this).val()];
                } else {
                    updateElementItems = {};
                }
            }

            if (Object.size(updateElementItems) > 0) {
                for (key in updateElementItems) {
                    if (updateElementItems[key] !== '-- Select --') {
                        options.push('<option value="' + updateElementItems[key] + '"' + ((updateElementSelected === updateElementItems[key]) ? ' selected' : '') + '>' + updateElementItems[key] + '</option>');
                    }
                }

                updateElement.html(empty + options.join(''));
            } else {
                updateElement.html(empty);
            }
        });
    };

    return CategorySelector;
}));