$(document).on('change', '[data-select-options]', function () {
    function swapState(el, show) {
        if(!show) {
            el.hide(0);
            if(!el.hasClass('hidden')) {
                el.addClass('hidden');
            }
            if(el.hasClass('shown')) {
                el.removeClass('shown');
            }
        } else {
            el.show(0);
            if(!el.hasClass('shown')) {
                el.addClass('shown');
            }
            if(el.hasClass('hidden')) {
                el.removeClass('hidden');
            }
        }
    }
    var self = $(this),
        options = (self.attr('data-select-options')) ? self.attr('data-select-options').split('|') : null,
        classes = (self.attr('data-select-toggle-classes')) ? self.attr('data-select-toggle-classes').split('|') : null,
        ids = (self.attr('data-select-toggle-ids')) ? self.attr('data-select-toggle-ids').split('|') : null,
        actions = (self.attr('data-select-actions')) ? self.attr('data-select-actions').split('|') : null,
        defaults = (self.attr('data-select-default-show')) ? self.attr('data-select-default-show').split('|') : null;

    if (classes && classes.length > 0) {
        for (var i = classes.length - 1; i >= 0; i--) {
            swapState($('[class*="' + classes[i] + '"]'), false);
        }
    }

    if (ids && ids.length > 0) {
        for (var i = ids.length - 1; i >= 0; i--) {
            swapState($('[id="' + ids[i] + '"]'), false);
        }
    }
    if (options.indexOf(self.val().toLowerCase()) >= 0) {
        if (actions[options.indexOf(self.val().toLowerCase())] === 'hide') {
            if (classes && classes.length > 0) {
                swapState($('[class*="' + classes[options.indexOf(self.val().toLowerCase())] + '"]'), false);
            } else {
                swapState($('[id="' + ids[options.indexOf(self.val().toLowerCase())] + '"]'), false);
            }
        }

        if (actions[options.indexOf(self.val().toLowerCase())] === 'show' || actions[options.indexOf(self.val().toLowerCase())] === 'showonly') {
            if (classes && classes.length > 0) {
                swapState($('[class*="' + classes[options.indexOf(self.val().toLowerCase())] + '"]'), true);
            } else {
                swapState($('[id="' + ids[options.indexOf(self.val().toLowerCase())] + '"]'), true);
            }
        }

        if (actions[options.indexOf(self.val().toLowerCase())] === 'showonly') {
            if(defaults) {
                for (var i = defaults.length - 1; i >= 0; i--) {
                    swapState($('.' + defaults[i]), true);
                }
            }
        }
    } else {
        if (defaults) {
            for (var i = defaults.length - 1; i >= 0; i--) {
                swapState($('.' + defaults[i]), true);
            }
        }
    }

    if ($('body.assets-form').length > 0 || $('body.epa-pcw-form').length > 0 || $('body.epa-property-form').length > 0) {
        var container = self.parent().parent(), i = 0;
        if($(self.parent().parent()).width() > 767) {
            $(container).children().each(function (index, currentElement) {

                    if ($(currentElement).hasClass('shown')) {
                        i++;
                    }

                    if (i == 4) {
                        i = 0;
                        $(currentElement).css('margin-right', '0px');
                        $(currentElement).css('margin-bottom', '0px');
                        $(currentElement).css('padding-bottom', '0px');
                    } else {
                        $(currentElement).css('margin-right', '20px');
                    }
                }
            );
        }
    }
});