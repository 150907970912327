(function(root, factory) {
    if(typeof exports === 'object') {
        module.exports = factory();
    }
    else if(typeof define === 'function' && define.amd) {
        define('Modal', [], factory);
    }

    root.Modal = factory();

}(this, function() {

    var Modal = function () {
        var self = this;

        self.modalSRC = $('#modal-dialog-js');
        self.modalTemplateHTML = $('#modal-dialog-template-js');
        self.modalHTML = '';

    };

    Modal.prototype.openModalDialog = function (dialogData) {
        var self = this;

        if (dialogData) {
            try {
                dialogData = $.parseJSON(dialogData);
            } catch (e) {
                // do nothing
            }
        } else {
            return;
        }

        if (typeof(dialogData) === 'object') {
            self.modalHTML = tmpl(self.modalSRC.html(), dialogData);
        } else {
            return;
        }

        if (dialogData.buttons && dialogData.buttons.length > 0) {
            var actions = $(self.modalHTML);

            // If no need for button
            if (dialogData.buttons.indexOf('save') < 0) {
                actions.find('[data-btn-save]').remove();
            }

            // If no need for button
            if (dialogData.buttons.indexOf('dont-save') < 0) {
                actions.find('[data-btn-dont-save]').remove();
            }

            // If no need for button
            if (dialogData.buttons.indexOf('cancel') < 0) {
                actions.find('[data-btn-cancel]').remove();
            }

            // If no need for button
            if (dialogData.buttons.indexOf('submit') < 0) {
                actions.find('[data-btn-submit]').remove();
            }

            // If no need for button
            if (dialogData.buttons.indexOf('continue') < 0) {
                actions.find('[data-btn-continue]').remove();
            }

            // If no need for button
            if (dialogData.buttons.indexOf('dont-continue') < 0) {
                actions.find('[data-btn-dont-continue]').remove();
            }

            self.modalHTML = actions[0];
        }

        // Clean up dialogs in document.
        if (self.modalTemplateHTML.length > 0) {
            self.modalTemplateHTML.remove();
        }

        $(document.body).append(self.modalHTML);
        $('#modal-dialog-template-js').addClass('open');

        return self.modalHTML;
    };

    Modal.prototype.closeModalDialog = function () {
        var self = this;

        self.modalTemplateHTML.addClass('close').removeClass('open');
        setTimeout(function () {
            self.modalTemplateHTML.remove();
        }, 400);
    };

    // Open modal onclick.
    $(document).on('click', '[data-open-dialog]', function (e) {
        e.preventDefault();
        new Modal().openModalDialog($(this).attr('data-open-dialog'));
    });

    // Close modal onclick.
    $(document).on('click', '#modal-dialog-template-js [data-btn-cancel]', function (e) {
        e.preventDefault();
        new Modal().closeModalDialog();
    });

    // Close modal onclick.
    $(document).on('click', '#modal-dialog-template-js [data-btn-dont-save]', function (e) {
        e.preventDefault();
        new Modal().closeModalDialog();
    });

    return Modal;
}));