(function(root, factory) {
    if(typeof exports === 'object') {
        module.exports = factory();
    }
    else if(typeof define === 'function' && define.amd) {
        define('Complete', [], factory);
    }

    root.Complete = factory();

}(this, function() {

    var Complete = function () {
        var self = this,
            count = 0,
            metRequired = 0,
            multipleArray = [];

        $('input[type="radio"][required]:visible').each(function () {
            if (multipleArray.indexOf(this.name) < 0) {
                multipleArray.push(this.name);
            }
        });

        $('input[type="radio"][required].always').each(function () {
            if (multipleArray.indexOf(this.name) < 0) {
                multipleArray.push(this.name);
            }
        });

        for (var i = multipleArray.length - 1; i >= 0; i--) {
            var radioGroup = $('input[type="radio"][required][name="' + multipleArray[i] + '"]:visible');

            radioGroup.each(function () {
                var self = $(this);

                if (self.attr('required')) {
                    if (!self.hasClass('error')) {
                        if (this.checked) {
                            metRequired++;
                        }
                    }
                }
            });
        }

        for (var i = multipleArray.length - 1; i >= 0; i--) {
            var radioGroup = $('input[type="radio"][required][name="' + multipleArray[i] + '"].always');

            radioGroup.each(function () {
                var self = $(this);

                if (self.attr('required')) {
                    if (!self.hasClass('error')) {
                        if (this.checked) {
                            metRequired++;
                        }
                    }
                }
            });
        }

        count = count + multipleArray.length;

        $('input:visible, select:visible, input.always, select.always' ).not('input[type="radio"]').each(function () {
            var self = $(this);

            if (self.attr('required')) {
                count++;

                if (!self.hasClass('error')) {
                    if ((this.type === 'radio' || this.type === 'checkbox') && this.checked) {
                        metRequired++;
                    }

                    if (this.type !== 'radio' && this.type !== 'checkbox' && self.val() !== '') {
                        metRequired++;
                    }
                }
            }
        });

        if (!window.lastCompletion) {
            window.lastCompletion = {
                percent: null,
                count: null,
                all: null
            }
        }

        var totalComplete = 0;

        $('#main-nav-slider-js').find('a > div > span').each(function () {
            if ($(this).parent().width() === $(this).width()) {
                totalComplete++;
            }
        });
        if (window.lastCompletion.all !== totalComplete && $('body').hasClass('will-form')) {
            $('.submittable').prop("disabled", false);

            window.lastCompletion.all = totalComplete;
        }

        if (window.lastCompletion.all !== totalComplete && $('body').hasClass('account-form')) {
            if (totalComplete == 2) {
                $('.submittable').prop("disabled", false);
            } else {
                $('.submittable').prop("disabled", true);
            }

            window.lastCompletion.all = totalComplete;
        }

        if (count === 0) {
            if (!$('#current-page-completeness').parent().parent().hasClass('complete')) {
                $('#current-page-completeness').width('100%');
                if(!$('body').hasClass('declaration')) {
                    $(document.body).addClass('editing');
                }
                $('#current-page-completeness').parent().parent().addClass('complete');
                $('.highlightFields').html('&nbsp;');
            }
            if ($('#_progress').val() !== 100) {
                $('#_progress').val(100);
            }

            window.lastCompletion = {
                percent: null,
                count: null,
                all: null
            }
        } else {

            if (window.lastCompletion.percent !== ((metRequired / count) * 100) || window.lastCompletion.count !== count) {
                $('#current-page-completeness').width((metRequired / count) * 100 + '%');
                $('#_progress').val((metRequired / count) * 100);

                if (((metRequired / count) * 100) === 100) {
                    $('#current-page-completeness').parent().parent().addClass('complete');
                    $('#_progress').val(100);
                } else {
                    $('#current-page-completeness').parent().parent().removeClass('complete');
                    $('#_progress').val((metRequired / count) * 100);
                }

                if ((count - metRequired) > 0) {
                    $('.highlightFields').html('This section has <a href="#">' + (count - metRequired) + ' fields</a> to fill out before it\'s complete.');
                    $('#_progress').val((metRequired / count) * 100);
                } else {
                    $('.highlightFields').html('&nbsp;');
                    $('#_progress').val(100);
                }

                window.lastCompletion = {
                    percent: (metRequired / count) * 100,
                    count: count
                }
            }
        }

    };

    return Complete;
}));

$('#main-nav-slider-js').find('.submit').not('.disabled').on('click', function (e) {
    e.preventDefault();
    $('[id$=-form-js]').find('[type="submit"]').trigger('click');
});