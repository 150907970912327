(function(root, factory) {
    if(typeof exports === 'object') {
        module.exports = factory();
    }
    else if(typeof define === 'function' && define.amd) {
        define('Popup', [], factory);
    }

    root.Popup = factory();

}(this, function() {
    // Only enable js popups for desktop browsers.
    if ($(window).width() >= 768) {

        var Popup = function () {};

        Popup.prototype.trigger = function (that, popupElement) {
            var self = $(that);

            if (!popupElement) {
                var popupElement = $('[id="' + self.attr('data-popup-element') + '"]');
            }

            popupElement.parent().css('position', 'relative');

            if (popupElement.hasClass('open')) {
                self.removeClass('open');
                this.close(popupElement);
            } else {
                self.addClass('open ' + popupElement.attr('class').replace('popup', ''));
                this.open(popupElement);
            }
        };

        Popup.prototype.open = function (popupElement) {
            popupElement.addClass('open');
        };

        Popup.prototype.close = function (popupElement) {
            popupElement.addClass('closing');
            popupElement.removeClass('open');

            setTimeout(function () {
                popupElement.removeClass('closing');
            }, 300);
        };

        var popup = new Popup();

        // Open popup via click.
        $(document).on('click', '[data-popup-element][data-popup-event="click"]', function (e) {
            e.stopPropagation();
            if ($(this).attr('data-popup-block-event') === '') {
                e.preventDefault();
                e.stopPropagation();
            }
            popup.trigger(this);
        });
        // Open popup via focus
        $(document).on('focus blur', '[data-popup-element][data-popup-event="focus"]', function (e) {
            //e.preventDefault();
            popup.trigger(this);
            var elem_id = $(this).attr('id');
            var id = $(this).parent().attr('id');
        });

        // Close popup link
        $(document).on('click', '.popup.wclose > span', function (e) {
            e.preventDefault();
            e.stopPropagation();

            popup.trigger(this, $(this).parent());

            var elem_id = $(this).attr('id');
            var id = $(this).parent().attr('id');
            $('[data-popup-element="' + id + '"]').removeClass('open');
        });
    }
}));