function cleanNumber(s) {
    return Number(s.replace(/[^0-9\.]+/g,""));
}
function formatCurrency(v) {
    return '$' + v.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

function updateTotal() {
    var businessTotal = cleanNumber($('#business-total').find('.lossengeTotal p').text());
    var assetTotal = cleanNumber($('#asset-total').find('.lossengeTotal p').text());
    var liabilityTotal = cleanNumber($('#liability-total').find('.lossengeTotal p').text());

    var total = businessTotal + assetTotal - liabilityTotal;
    var output = formatCurrency(total);
    // add (###,###) for negative number
//    output = total < 0 ? '(' + output.replace('-', '') + ')' : output;
    var grandTotal = $('#grand-total').find('.lossengeTotal p');
    $(grandTotal).text(output);

}

function recalc(type) {
    var total = 0;
    $('input[data-total=' + type + ']').each(function( index ) {
        p = $(this).parent().parent().parent().parent().parent();
        if($(p).css("display") === "block") {
            total += cleanNumber($(this).val());
        }
    });
    var lossengeTotal = $('#' + type + '-total').find('.lossengeTotal p');
    var lossenge = $('#' + type + '-total');
    $(lossengeTotal).text(formatCurrency(total));

    if(typeof(total) == "undefined" || total == null || total == 0) {
        $(lossenge).parent().parent().css('display','none');
    } else {
        $(lossenge).parent().parent().css('display','block');
    }
    updateTotal();
}

function recalcAL() {
    recalc('asset');
    recalc('liability');
}

function recalcB() {
    recalc('business');
}

$(document).on('blur keyup', 'input[data-total="asset"]', function (e) {
    recalc('asset');
});

$(document).on('blur keyup', '[data-total="liability"]', function (e) {
    recalc('liability');
});

$(document).on('blur keyup', '[data-total="business"]', function (e) {
    recalc('business');
});


$(document).on('click', '.duplicated .remove-x', function (e) {
    if ($('#assets-form-js').length) {
        recalcAL();
    }
});

$("body.business .skip-block").attrchange({
    trackValues: true, // set to true so that the event object is updated with old & new values
    callback: function(evnt) {
        if(evnt.attributeName == "style") { // which attribute you want to watch for changes
            recalcB();
        }
    }
});

$("body.assets-liabilities .skip-block").attrchange({
    trackValues: true, // set to true so that the event object is updated with old & new values
    callback: function(evnt) {
        if(evnt.attributeName == "style") { // which attribute you want to watch for changes
            recalcAL();
        }
    }
});
