(function(Reflux, VaultActions, AuthStore, global, _) {
	'use strict';

	global.VaultStore = Reflux.createStore({
		// sets up listeners to all publishers in Actions, using onKeyname as callbacks
		listenables: [VaultActions],

		key: 'vault.state',

		FileTypes: {
			DEFAULT: { icon: 'icon-file-o' },
			PDF: { icon: 'icon-file-pdf-o' },
			TXT: { icon: 'icon-file-text-o' },
			IMG: { icon: 'icon-file-image-o' },
			ARCHIVE: { icon: 'icon-file-archive-o' },
			WORD: { icon: 'icon-file-word-o' },
			XLS: { icon: 'icon-file-excel-o' },
			PPT: { icon: 'icon-file-powerpoint-o' },
			AUDIO: { icon: 'icon-file-audio-o' },
			VIDEO: { icon: 'icon-file-video-o' }
		},

		state: {
			loaded: false,
			error: null,
			documents: [],
			fileTypes: null,
			fileExtensions: {}
		},

		onLoad: function(authToken) {
			VaultActions.fileExtensions();
		},
		onLoadCompleted: function(docs) {
			this.state.loaded = true;
			this.state.documents = docs;
			// update icon for each document
			_.each(this.state.documents, _.bind(function(doc){
				doc.icon = this.getIcon(doc.fileExtension);
			}, this));
			this.update();
		},
		onLoadFailed: function(xhr, textStatus, statusText) {
			this.state.loaded = true;
			this.state.error = !statusText ? 'Unavailable' : statusText;
			this.update();
		},

		onFileExtensions: function() { },
		onFileExtensionsCompleted: function(extensions) {
			this.state.fileExtensions = extensions;
			// update icon for each document
			_.each(this.state.documents, _.bind(function(doc){
				doc.icon = this.getIcon(doc.fileExtension);
			}, this));
			this.update();
		},
		onFileExtensionsFailed: function(resp, textStatus, xhr) { },

		getIcon: function(extension) {
			return (this.FileTypes[this.state.fileExtensions[extension]] || this.FileTypes.DEFAULT).icon;
		},

		onUpload: function(authToken, file, tags) {
			var ext = file.name.substring(file.name.lastIndexOf('.'));
			this.state.documents.splice(0, 0, { name: file.name, contentType: file.type, fileExtension: ext, fileSize: file.size, tags: tags, progress: 0, icon: this.getIcon(ext) })
			this.update();
		},
		onUploadProgress: function(file, evt) {
			var done = evt.position || evt.loaded,
				total = evt.totalSize || evt.total,
                completed = Math.floor(done / total * 100);

			_.find(this.state.documents, function(doc) { return file.name == doc.name && _.has(doc, 'progress'); }).progress = completed;
			this.update();
		},
		onUploadCompleted: function(file, data) {
			var documents = JSON.parse(data);
			// update extension
			// update icon for each document
			_.each(documents, _.bind(function(doc){
				doc.icon = this.getIcon(doc.fileExtension);
			}, this));
			_.remove(this.state.documents, function(doc) { return file.name == doc.name && _.has(doc, 'progress'); });
			this.state.documents = _.union(documents, this.state.documents);
			this.update();
		},
		onUploadFailed: function(file, textStatus, resp, xhr) {
			_.find(this.state.documents, function(doc) { return file.name == doc.name && _.has(doc, 'progress'); }).error = textStatus;
			this.update();
		},

		onEdit: function(authToken) { },
		onEditCompleted: function(fields) {
			var editDocument = _.find(this.state.documents, function(doc) { return doc.id == fields.id });
			_.each(_.omit(fields, ['id']), function(value, key) {
				editDocument[key] = value || '';
			});
			this.update();
		},
		onEditFailed: function(document, resp, textStatus, xhr) {
			_.find(this.state.documents, function(doc) { return doc.id == document.id }).error = textStatus;
			this.update();
		},

		onDelete: function(authToken, id) { },
		onDeleteCompleted: function(id) {
			_.remove(this.state.documents, function(doc) { return doc.id == id; });
			this.update();
		},
		onDeleteFailed: function(id, resp, textStatus, xhr) {
			_.find(this.state.documents, function(doc) { return doc.id == id }).error = textStatus;
			this.update();
		},

		onShare: function(authToken) { },
		onShareCompleted: function(document) {
			var sharedDoc = _.find(this.state.documents, function(doc) { return doc.id == document.id; });
			_.unset(sharedDoc, ['shareUpdated']);
			this.update();
		},
		onShareFailed: function(document, resp, textStatus, xhr) {
			var sharedDoc = _.find(this.state.documents, function(doc) { return doc.id == document.id; });
			sharedDoc.error = textStatus;
			_.unset(sharedDoc, 'shareUpdated');
			this.update();
		},

		onShareAdvisor: function(document) { },
		onShareAdvisorCompleted: function(document) {
			var sharedDoc = _.find(this.state.documents, function(doc) { return doc.id == document.id; });
			sharedDoc.isSharedAdviser = document.isSharedAdviser;
			this.update();
		},
		onShareAdvisorFailed: function(document, resp, textStatus, xhr) {
			_.find(this.state.documents, function(doc) { return doc.id == document.id; }).error = textStatus;
			this.update();
		},

		onAddShare: function(document, share) {
			var nextId = _.reduce(_.compact(document.shares), function(max, share) { return Math.max(max, share.id); }, 0) + 1;
			(document.shares || (document.shares = [])).push({ id: nextId, email: '' });
			document.shareUpdated = true;
			this.update();
		},
		onUpdateShare: function(document, share, value) {
			share.email = value;
			share.isValid = this.validateEmail(value);
			document.shareUpdated = true;
			this.update();
		},
		onDeleteShare: function(document, share) {
			_.remove(document.shares, function(s) { return !s || s.id == share.id; });
			document.shareUpdated = true;
			this.update();
		},

		onSelect: function(document) {
			// unselect all other documents - maybe lets not do this
			//_.each(this.state.documents, function(doc) { if (doc.id != document.id) _.unset(doc, 'selected'); });

			document.selected = !document.selected;
			this.update();
		},

		validateEmail: function(email) {
			var regex = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-zA-Z0-9-]*[a-z0-9])?\.)+(?:[a-zA-Z]{2,})\b$/;
			return regex.test(email);
		},

		update: function() {
			localStorage.setItem(this.key, JSON.stringify(this.state));
			this.trigger(this.state); // sends the updated state to all listening components
		},
		// this will be called by all listening components as they register their listeners
		getInitialState: function() {
			// VaultActions.fileExtensions();

			// var localState = JSON.parse(localStorage.getItem(this.key));
			// this.state = localState && !localState.error ? localState : this.state;
			return this.state;
		}
	});

})(window.Reflux, window.VaultActions, window.AuthStore, window, window._);
