(function (Reflux, DocumentApi, global) {
	'use strict';

	var VaultActions = Reflux.createActions({
		'load': { asyncResult: true },
		'download': {},
		'upload': { asyncResult: true, children: ['progress'] },
		'edit': { asyncResult: true },
		'delete': { asyncResult: true },
		'share': { asyncResult: true },
		'shareAdvisor': { asyncResult: true },
		'select': {},
		'addShare':{},
		'updateShare':{},
		'deleteShare':{},
		'fileExtensions': { asyncResult: true }
	});

	VaultActions.load.listen(function (authToken) {
		DocumentApi.list(authToken)
			.done(this.completed)
			.fail(this.failed);
	});

	VaultActions.upload.listen(function (authToken, file, tags) {
		DocumentApi.upload(authToken, file, tags, function(e){ VaultActions.upload.progress(file, e); })
			.done(this.completed)
			.fail(this.failed);
	});

	VaultActions.edit.listen(function (authToken, fields) {
		DocumentApi.edit(authToken, fields)
			.done(this.completed)
			.fail(this.failed);
	});

	VaultActions.delete.listen(function (authToken, id) {
		DocumentApi.delete(authToken, id)
			.done(this.completed)
			.fail(this.failed);
	});

	VaultActions.share.listen(function (authToken, id, shares) {
		DocumentApi.share(authToken, id, shares)
			.done(this.completed)
			.fail(this.failed);
	});

	VaultActions.shareAdvisor.listen(function (authToken, id, shared) {
		DocumentApi.shareAdvisor(authToken, id, shared)
			.done(this.completed)
			.fail(this.failed);
	});

	VaultActions.fileExtensions.listen(function () {
		DocumentApi.fileExtensions()
			.done(this.completed)
			.fail(this.failed);
	});

	global.VaultActions = VaultActions;
	
})(window.Reflux, window.DocumentApi, window);