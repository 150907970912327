$('body.income').on("change", ".checkbox input[type=checkbox]", function() {
    if($(this)[0].checked) {
        $(this).parent().parent().css('background-color', '#f2f5f5');
        $(this).parent().css('color', '#585a5c');
    } else {
        $(this).parent().parent().css('background-color', '#fbfdfd');
        $(this).parent().css('color', '#808486');
    }
});

if (!bowser.android) {
    $('body.assets-form').find('[data-currency]').maskMoney({
        precision: 0,
        prefix: '$',
        allowZero: true
    });
} else {
    $('body.assets-form').find('[data-currency]').on('keydown', function (e) {
        if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || (e.keyCode >= 65 && e.keyCode <= 90)) {
            e.preventDefault();
            var chr_code = e.keyCode - 48 * Math.floor(e.keyCode / 48),
                chr = String.fromCharCode((96 <= e.keyCode) ? chr_code: e.keyCode);

            this.value = '$' + numberWithCommas(this.value.replace(/[^0-9.]/g, '') + chr.replace(/[^0-9.]/g, ''));
        }
    }).on('blur', function () {
        this.value = '$' + numberWithCommas(this.value.replace(/[^0-9.]/g, ''));
    });
}